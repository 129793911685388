<template>
    <div>
        <LineChart ref="distributionLineChart" :chart-data="chartData" :options="lineChartOptions" :width="Width" :height="Height" />
    </div>
</template>

<script>

    import { cloneDeep } from 'lodash';
    import LineChart from '@/components/charts/core/line-chart';
 
    export default {
        name: 'GainLossLineChart',

        props: {
            TaxRecords: {
                type: Array,
                default: () => [],
            },
            Width: {
                type: Number,
                default: () => 330,
            },
            Height: {
                type: Number,
                default: () => 330,
            },
        },

        /* COMPONENT DATA  */
        data() {
            return {
                monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                lineChartOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: true
                    },
                    onClick: this.handleDistributionLineChartClick,
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                                let label = data.labels[tooltipItem.index];
                                let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                return ' ' + label + ': $' + value;
                            }
                        }
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                // Include a dollar sign in the ticks
                                callback: function (value, index, values) {
                                    return '$' + value;
                                },
                                //fontColor: "black",
                                fontStyle: "900",
                            },
                        }],
                        xAxes: [{
                            ticks: {
                                fontColor: "black",
                                fontStyle: "bold"
                            },

                        }]
                    }
                }
            };
        },

        /* CHILD COMPONENTS  */
        components: {
            LineChart
        },

        /* COMPONENT METHODS  */
        methods: {

            resetChart() {
                //Reset the chart if available
                if (this.$refs.distributionLineChart) {   
                    var chart = this.$refs.distributionLineChart.$data._chart;
                    chart.update(); //Update chart
                }
            },
        },

        /* COMPUTED PROPERTIES */
        computed: {
          
            allDates() {
                //Retrieve a list of distinct Month/Year axis points to group gains and losses

                //Clone and sort this list by date
                var sortedTaxRecords = cloneDeep(this.TaxRecords);

                if (this.TaxRecords.length > 0) {

                    sortedTaxRecords.sort((a, b) => new Date(a.dateSold) - new Date(b.dateSold));

                    var startDate = new Date(sortedTaxRecords[0].dateSold);
                    var endDate = new Date(sortedTaxRecords[sortedTaxRecords.length - 1].dateSold);

                    //Get a list of month/years in between the start and end date
                    const getMonths = (fromDate, toDate) => {
                        
                        const fromYear = fromDate.getFullYear();
                        const fromMonth = fromDate.getMonth();
                        const toYear = toDate.getFullYear();
                        const toMonth = toDate.getMonth();
                        const months = [];
                        for (let year = fromYear; year <= toYear; year++) {
                            let month = year === fromYear ? fromMonth : 0;
                            const monthLimit = year === toYear ? toMonth : 11;
                            for (; month <= monthLimit; month++) {
                                months.push(this.monthNames[month] + " '" + year.toString().substr(2, 2))
                            }
                        }
                        return months;
                    }

                    var dates = getMonths(startDate, endDate);
                    return dates;
                }
                else
                    return [];
            },

            chartData() {

                var chartData = [];
                let gainArray = [];
                let lossArray = [];
                let labelArray = [];

                for (var x = 0; x < this.allDates.length; x++) {
                    var currentDate = this.allDates[x];

                    var totalGain = this.TaxRecords.filter(x => {
                        var recordDate = new Date(x.dateSold);
                        var label = this.monthNames[recordDate.getMonth()] + " '" + recordDate.getFullYear().toString().substr(2, 2);
                        if (currentDate === label && x.gain >= 0)
                            return x;
                    }).reduce((n, { gain }) => n + parseFloat(gain), 0);

                    var totalLoss = this.TaxRecords.filter(x => {
                        var recordDate = new Date(x.dateSold);
                        var label = this.monthNames[recordDate.getMonth()] + " '" + recordDate.getFullYear().toString().substr(2, 2);
                        if (currentDate === label && x.gain < 0)
                            return x;
                    }).reduce((n, { gain }) => n + parseFloat(gain), 0);

                    labelArray.push([currentDate]);
                    gainArray.push(totalGain.toFixed(2));
                    lossArray.push(totalLoss.toFixed(2));
                }

                chartData = {
                    labels: labelArray,
                    datasets: [
                        {
                            label: "Gains",
                            cubicInterpolationMode: 'monotone',
                            backgroundColor: 'rgba(39, 255, 56, 0.9)',
                            borderColor: 'black',
                            pointBackgroundColor: 'green',
                            borderWidth: 2,
                            pointBorderColor: 'black',
                            data: gainArray
                        },
                        {
                            label: "Losses",
                            cubicInterpolationMode: 'monotone',
                            backgroundColor: 'rgba(255, 0, 0, 0.9)',
                            borderColor: 'black',
                            pointBackgroundColor: 'red',
                            borderWidth: 2,
                            pointBorderColor: 'black',
                            data: lossArray
                        }
                    ]
                };

                return chartData;
            }
        }
    };
</script>